.prod__info {
  margin-left: auto;
  margin-right: auto;
}

.prod__desc {
  font-size:medium;
  text-align: left;
}

.prod__text {
  text-align: left;
  width: 90%;
}

.prod__price {
  margin-top: 5px;
  margin-bottom: -10px;
  text-align: left;
  font-size:x-large;
}

.prod__sel {
    display: block;
    width: 90%;
    height: 40px;
    font-size:medium;
    border: 1px solid transparent;
    border-radius: 10px!important;
    border-color: #646566 #6083a0 #939596;
    color: #111;
    cursor: pointer;
    box-shadow: 0 5px 5px #939596;
    padding: 10px;
    
}

.button__cart {
 display:flex;
 padding-right: 30px;
}

.prod__button {
  font-size: large;
  width: 85%;
  height: 45px;
  background-color: #03202e;
  border: 1px solid;
  margin-left: 20px;
  border-color: #8fc5e9 #6083a0 #477286;
  color: rgb(241, 247, 242);
  box-shadow: 0 5px 5px #939596;
  cursor: pointer;
  border-radius: 25px!important;

}