
.detailInfo{
  width: 100%;
  display:flex;
  justify-content: center;
  max-width: 1500px;
  background-color: white;
  flex-wrap: wrap;
  padding:3rem 4rem;
  margin-left: auto;
  margin-right: auto;
}
  .detailLeft {
    float: left;
    flex:2;
    max-width: 850px;
    height:auto;
    width: auto;
    margin-right: 30x;
    margin-left: 10px;
    margin-top: 30px;
    padding-right: 20px;
  }
  
  .detailRight{
    float: left;
    width: 40%;
    height:auto;
    margin-left: 60px;
    margin-right: 30px;
    margin-top: 15px;
    padding-right: 25px;
    padding-left: 25px;
    
  }

  @media (max-width: 768px){
    .detailInfo{
     display:inline-block;
     margin-left: 5px;
     padding: 2em;
    
    } 
    .detailLeft{
      margin-left: 5px;
      margin-right:5px;
      max-width: 390px;
      margin-left: 1px;
    }
    .detailRight{
      margin-left: 5px;
      width: 70%;
    }

  }
