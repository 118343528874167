.orders {
    padding: 20px 80px;
}

.orders > h1 {
  margin: 30px 0;
}

@media (max-width: 768px){
  .orders{
    padding: 5px;
  }
}