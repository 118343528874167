
.prod_list{
    display: flex;
    flex-direction:column;
   }
   .filter_container {
       display: flex;
       /* align-items:center; */
       gap: 10px;
     }
   .filter_text{
       padding-left:20px;
       padding-top: 5px;
       font-size: medium;
       font-weight: 600;
   }
   .filter_selt{
     cursor:pointer;
     width:15%;
   }

   .prod_item{
       /* width: 100%; */
       display: flex;
       padding: 5px;
       flex-wrap: wrap;
       justify-content: space-between;
   }

  

   @media (max-width: 768px) { 
    .filter_selt{
        width: 50%;
    }
   }