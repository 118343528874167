.product {
     flex:1;
     margin:5px;
     min-width: 380px;
     /* width: 400px; */
     height:420px;
     display:flex;
     align-items:center;
     justify-content: center;
     background-color: #f5fbfd;
     position: relative;
};

.product>img {
    height:420px;
    width: 400px;
    object-fit: contain;
    margin-bottom: 10px;
};

.product_info {
    height: 100px;
    margin-bottom: 15px;
}
.product_title { 
    margin-left: 10px;
    /* font-size: large; */
    font-weight: 650;
    /* color:#20498f; */
}

.product_info>button{
    background-color: #b3c7d3;
    border: 1px solid;
    margin-left: 20px;
    border-color: #8fc5e9 #6083a0 #477286;
    color: #111;
    cursor: pointer;
    /* visibility:hidden; */
}

.product_info>button:hover {color: white};

.product_price{
  margin-top: 5px;
  margin-bottom: -10px;
};

