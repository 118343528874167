.ship_policy {
    margin:10px;
    padding: 20px 180px;
    text-align: left;
}


@media (max-width: 1024px){
    .ship_policy{
       margin:5px;
       padding: 20px 10px;
       text-align: left;

    } 
  }