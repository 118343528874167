.login {
    background-color: white;
    height: 75vh;
    display: flex;
    /* flex-direction; */
    align-items: center;
    justify-content: center;
}

.left_login{
    padding-right: 60px;
    margin-right: 50px;
    width: 350px;
}

.login_logo{
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
}

.login_container{
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border:1px solid lightgray;
    padding: 20px;
}

.login_container>h1{
    font-weight: 500;
    margin-bottom: 20px;
}

.login_container>form>h5{
    margin-bottom: 5px;
}
.login_container>form>input{
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
}

.login_container>p{
    margin-top: 15px;
    font-size: 12px;
}
.sign__button{
    width: 200px;
    height: 30px;
    border: 1px solid;
    background-color: #03202e;
    border-color: #8fc5e9 #6083a0 #477286;
    color: rgb(241, 247, 242);
    box-shadow: 0 5px 5px #939596;
    cursor: pointer;
    border-radius: 25px!important;
}
.login_registerButton{
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
}

@media (max-width: 768px) { 
    .left_login{padding-right: 5px;
               margin-right: 10px;
               width: 200px;}
    .login_logo{
                margin-top: 20px;
                font-size: medium;
                margin-bottom: 20px;
                object-fit: contain;
                width: 160px;
                margin-right: auto;
                margin-left: auto;
            }
    .right_login{padding-right: 5px;
                margin-right: 10px;
                width: 150px;}
}