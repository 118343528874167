.checkout__main{
    display: flex;
    /* flex-direction: row; */
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: 100px;
    margin-right: 50px;
    max-width: 1500px;
    background-color: white;
}

 .checkout__left{
    /* flex:3; */
    display:block;
    padding-left: 10px;
    margin-top: 15px;
    /* width: 75%; */
 }

  .checkout__ad{
    width: 100%;
    margin-bottom: 10px;
  }

  .checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
  }

  .Basket__item{
    width: 100%;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    justify-content:space-evenly;
  }
  .home__button{
    visibility: hidden;
  }
 .checkout__right {
    flex:1;
    width: 25%;
    margin-left: 10px;
    margin-top: 15px;
 }
 @media (max-width: 768px){
  .checkout__main{
    display: block;
    margin-left: 50px;
  }
    .home__button{width: 200px;
      height: 30px;
      border: 1px solid;
      background-color: #03202e;
      border-color: #8fc5e9 #6083a0 #477286;
      color: rgb(241, 247, 242);
      box-shadow: 0 5px 5px #939596;
      cursor: pointer;
      border-radius: 25px!important;
      visibility: visible;
  }
 }