.order{
    /* padding: 10px; */
    margin: 10px 60px;
    height:auto;
    width: 80%;
    border: 1px solid lightgray;
    background-color: rgb(242, 244, 246);
    position: relative;

}
.order_info{
  display:flex;
  justify-content:center;
  /* max-width: 1500px; */
  flex-wrap: wrap;
}

.orderLeft {
    float: left;
    /* flex:3; */
    /* width: 60%; */
    /* max-width: 850px; */
    height:auto;
    width: auto;
    margin-right: 40x;
    margin-left: 10px;
    margin-top: 5px;
    padding-right: 40px;
  }
  
  .orderRight{
    /* flex:1; */
    /* width: 40%; */
    height:auto;
    margin-left: 20px;
    /* margin-right: 30px; */
    margin-top: 15px;
    /* padding-right: 25px; */
    padding-left: 25px;
    
  }

  @media (max-width: 768px){
    .order_info{
     display:inline-block;
    } 
    .orderRight{
      padding-left: 0px;
      margin-left: 0px;
    }
  }

/* .order_id{
    position: absolute;
    top: 40px;
    right: 20px;
} */

.order_total{
    font-weight: 500;
    text-align: right;
}