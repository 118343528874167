.header {
    height: 60px;
    display: flex;
    align-items: center;
    /* background-color: #131921; */
    /* background-color: #131921; */
    background: #131921;
    background: linear-gradient(to left bottom,#314c72, #eff5f4);
    /* mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0)); */
    position: sticky;
    top: 0;
    z-index: 100;
}

.header_logo {
    width: 75px;
    object-fit: contain;
    /* margin: 10px 20px; */
    margin-top: 2px;
    margin-left: 110px;

}
.header_home {
    margin-left: 50px;
    font-size: medium;
    font-weight: 600;
    color: white;
}

.header_search {
    display: flex;
    flex: 1; 
    align-items: center;
    border-radius: 24px;
}

.header_searchInput{
    height: 12px;
    padding: 10px;
    margin-left: 260px;
    border: none;
    width: 50%;
    /* position: relative; */
}

.header_searchIcon{
    padding: 5px;
    height:22px !important;
    background-color: #9BC9E3;
}

.header_optionLineOne{
    font-size:10px;
}

.header_optionLineTwo {
    font-size: 13px;
    font-weight:800;
}

.header_optionBasket {
    display: flex;
    align-items: center;
    color: white;
}

.social_icon {
    display: flex;
    align-items: center;
    color: white;
    margin-right: 30px;
    cursor: pointer;
    padding: 10px;
}

.header_basketCount{
    margin-left: 10px;
    margin-right: 10px;
}

.header_nav {
    display: flex;
    justify-content: space-evenly;
    margin-right: 150px;
}

.header_option{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color:   white;
}

@media (max-width: 768px) { 
    .header_logo {margin-left: 5px;}
    .header_home {display: none;}
    .header_search {display: none;}
    .header {padding-top: 10px;}
    .social_icon{display: none;}
    .header_nav{margin-right: 2px;}
 }